import { useState, useEffect, createContext, useContext } from 'react'

import { useAuth } from '../auth/auth'
import { useDisponibilities } from '../../hooks'

const initialValues = {
  datas: {
    teams: [],
    chantiers: [],
    positions: [],
    absences: [],
    disponibilities: [],
    employees: [],
    chantiersConsumedTimes: [],
    vehicules: [],
    clientSlug: '',
    invoiceLabels: [],
    invoiceStates: [],
    licenses: [],
    companies: [],
    genders: [],
    contracts: [],
    measures: [],
    defaultHours: [],
    tasks: [],
    defaultDays: 0,
    decimalVentilation: false
  },
  reload: {
    teams: () => {},
    chantiers: () => {},
    positions: () => {},
    employees: () => {},
    vehicules: () => {},
    consumedTime: () => {},
    invoiceLabels: () => {},
    licenses: () => {},
    companies: () => {},
    measures: () => {},
    defaultDaysHours: () => {},
    tasks: () => {}
  }
}

const DatasContext = createContext(initialValues)

const DatasProvider = ({ children }) => {
  const { callApi, isConnected, user } = useAuth()

  const disponibilities = useDisponibilities()
  const [teams, setTeams] = useState([])
  const [chantiers, setChantiers] = useState([])
  const [positions, setPositions] = useState([])
  const [absences, setAbsences] = useState([])
  const [employees, setEmployees] = useState([])
  const [chantiersConsumedTimes, setChantiersConsumedTimes] = useState([])
  const [vehicules, setVehicules] = useState([])
  const [clientSlug, setClientSlug] = useState('')
  const [invoiceStates, setInvoiceStates] = useState([])
  const [invoiceLabels, setInvoiceLabels] = useState([])
  const [licenses, setLicenses] = useState([])
  const [companies, setCompanies] = useState([])
  const [measures, setMeasures] = useState([])
  const [genders] = useState([{ value: 'Masculin', label: 'Masculin' }, { value: 'Féminin', label: 'Féminin' }, { value: 'Non défini', label: 'Non défini' }])
  const [contracts] = useState([
    { value: 'CDI', label: 'CDI' },
    { value: 'CDD', label: 'CDD' },
    { value: 'Intérim', label: 'Intérim' },
    { value: "Contrat d'apprentissage", label: "Contrat d'apprentissage" },
    { value: 'Contrat de professionnalisation', label: 'Contrat de professionnalisation' },
    { value: 'Contrat unique d’insertion', label: 'Contrat unique d’insertion' },
    { value: 'Contrat d’accompagnement dans l’emploi', label: 'Contrat d’accompagnement dans l’emploi' },
    { value: 'Contrat initiative emploi', label: 'Contrat initiative emploi' },
    { value: 'Sous-traitants', label: 'Sous-traitants' }
  ])

  const [countries, setCountries] = useState([])
  const [defaultHours, setDefaultHours] = useState([])
  const [defaultDays, setDefaultDays] = useState(0)
  const [decimalVentilation, setDecimalVentilation] = useState(false)
  const [hideWeekEnd, setHideWeekEnd] = useState(false)
  const [tasks, setTasks] = useState([])

  const loadDefaultDaysHours = () => {
    callApi({
      method: 'get',
      url: 'admin/setting'
    })
      .then(res => {
        if (!res) {
          setDefaultDays(1)
          return
        }

        setDecimalVentilation(res?.data?.data?.decimalVentilation)
        setHideWeekEnd(res?.data?.data?.hideWeekEnd)
        setDefaultDays(res?.data?.data?.defaultDays[0][0]?.value ?? 1)
        setDefaultHours(res?.data?.data?.defaultHours.map(def => {
          const timeString = def.value.split(':')
          const minutes = parseInt(timeString[0]) * 60 + parseInt(timeString[1])
          const minutesToCent = minutes// minutes * 100 / 60
          return {
            ...def,
            minutesCent: minutesToCent
          }
        }))
      })
  }

  const loadTeams = () => {
    callApi({
      method: 'get',
      url: 'admin/teams'
    })
      .then(res => {
        setTeams(res?.data?.data ?? [])
      })
  }

  const loadChantiers = () => {
    callApi({
      method: 'get',
      url: 'admin/search/models/construction-sites?search='
    })
      .then(res => {
        setChantiers(
          (res?.data?.data ?? [])
            .sort((a, b) => ((a.label ?? '').toLowerCase() > (b.label ?? '').toLowerCase()) ? 1 : (((b.label ?? '').toLowerCase() > (a.label ?? '').toLowerCase()) ? -1 : 0))
            .map(c => ({ value: c.id, label: c.label, startDate: c.details.start_date, endDate: c.details.end_date }))
        )
      })
  }

  const loadConsumedTime = () => {
    callApi({
      method: 'get',
      url: 'admin/construction-sites/consumed_time'
    })
      .then(res => {
        const newChantiersConsumedTime = [];
        (res?.data?.data ?? []).forEach(timesheet => {
          if (!newChantiersConsumedTime[timesheet.id]) {
            newChantiersConsumedTime[timesheet.id] = 0
          }
          newChantiersConsumedTime[timesheet.id] += timesheet.consumedTime
        })
        setChantiersConsumedTimes(newChantiersConsumedTime)
      })
  }

  const loadPositions = () => {
    callApi({
      method: 'get',
      url: 'admin/search/models/employee-positions?search='
    })
      .then(res => {
        setPositions((res?.data?.data ?? [])
          .sort((a, b) => a.label > b.label)
          .map(c => ({ value: c.id, label: c.label })))
      })
  }

  const loadAbsences = () => {
    callApi({
      method: 'get',
      url: 'admin/absences'
    })
      .then(res => {
        setAbsences((res?.data?.data ?? []).map(c => ({ value: c.id, label: c.label, global: c.global, app_availability: c.app_availability })))
      })
  }

  const loadInvoiceStates = () => {
    callApi({
      method: 'get',
      url: 'admin/search/models/invoice-states?search='
    })
      .then(res => {
        setInvoiceStates((res?.data?.data ?? []).map(c => ({ value: c.id, label: c.label })))
      })
  }

  const loadInvoiceLabels = () => {
    callApi({
      method: 'get',
      url: 'admin/search/models/invoice-labels?search='
    })
      .then(res => {
        setInvoiceLabels((res?.data?.data ?? []).map(c => ({ value: c.id, label: c.label })))
      })
  }

  const loadEmployees = () => {
    callApi({
      method: 'get',
      url: 'admin/search/models/employees?search='
    })
      .then(res => {
        setEmployees((res?.data?.data ?? []).map(c => ({ value: c.id, label: c.label })))
      })
  }

  const loadVehicules = () => {
    callApi({
      method: 'get',
      url: 'admin/search/models/vehicles?search='
    })
      .then(res => {
        setVehicules((res?.data?.data ?? []).map(c => ({ value: c.id, label: c.label })))
      })
  }

  const loadClientSlug = () => {
    if (user.roles.filter(role => role.name === 'admin').length === 0) {
      return
    }

    callApi({
      method: 'get',
      url: 'admin/administration?search='
    })
      .then(res => {
        const clientSlug = (res?.data?.data[0]?.name ?? 'rien').split(' ')[0].toLowerCase()
        setClientSlug(clientSlug)
      })
  }

  const loadLicenses = () => {
    callApi({
      method: 'get',
      url: 'admin/search/models/licenses?search='
    })
      .then(res => {
        setLicenses((res?.data?.data ?? []).map(c => ({ value: c.id, label: c.label })))
      })
  }

  const loadCompanies = () => {
    callApi({
      method: 'get',
      url: 'admin/search/models/companies?search='
    })
      .then(res => {
        setCompanies((res?.data?.data ?? [])
          .sort((a, b) => a.label > b.label ? 1 : -1)
          .map(c => ({ value: c.id, label: c.label })))
      })
  }

  const loadCountries = () => {
    callApi({
      method: 'get',
      url: 'admin/search/models/countries?search='
    })
      .then(res => {
        setCountries((res?.data?.data ?? []).map(c => ({ value: c.id, label: c.label })))
      })
  }

  const loadMeasures = () => {
    callApi({
      method: 'get',
      url: 'admin/measures?search='
    })
      .then(res => {
        setMeasures((res?.data?.data ?? []))
      })
  }

  const loadTasks = () => {
    callApi({
      method: 'get',
      url: 'admin/tasks'
    })
      .then((res) => {
        setTasks(res?.data?.data ?? [])
      })
  }

  useEffect(() => {
    if (!isConnected) return
    loadTeams()
    loadChantiers()
    loadPositions()
    loadAbsences()
    loadEmployees()
    loadVehicules()
    loadConsumedTime()
    loadClientSlug()
    loadInvoiceLabels()
    loadInvoiceStates()
    loadLicenses()
    loadCompanies()
    loadCountries()
    loadDefaultDaysHours()
    loadMeasures()
    loadTasks()
  }, [isConnected])

  return (
    <DatasContext.Provider value={{
      datas: {
        teams,
        chantiers,
        positions,
        absences,
        disponibilities,
        disponibilitiesAll: user && (user.roles.filter(role => role.name === 'admin').length > 0 || user.roles.filter(role => role.name === 'cdt').length > 0)
          ? [
              { value: 'all', label: 'Toutes les disponibilités' },
              ...disponibilities
            ]
          : [...disponibilities],
        employees,
        chantiersConsumedTimes,
        vehicules,
        clientSlug,
        invoiceLabels,
        invoiceStates,
        licenses,
        companies,
        genders,
        contracts,
        countries,
        decimalVentilation,
        defaultDays,
        defaultHours,
        hideWeekEnd,
        measures,
        tasks
      },
      reload: {
        teams: loadTeams,
        chantiers: loadChantiers,
        positions: loadPositions,
        absences: loadAbsences,
        employees: loadEmployees,
        vehicules: loadVehicules,
        consumedTime: loadConsumedTime,
        clientSlug: loadClientSlug,
        invoiceLabels: loadInvoiceLabels,
        invoiceStates: loadInvoiceStates,
        licenses: loadLicenses,
        companies: loadCompanies,
        countries: loadCountries,
        measures: loadMeasures,
        defaultDaysHours: loadDefaultDaysHours,
        tasks: loadTasks
      }
    }}>
      {children}
    </DatasContext.Provider>
  )
}

const useDatas = () => {
  return useContext(DatasContext)
}

export { DatasProvider, useDatas }
